import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_ENDPOINTS } from '../pages/constants/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  baseUrl = "https://cp-tralsawala-backend.herokuapp.com/"

  constructor(private http: HttpClient) { }

  getAllRequests() {
    return this.http.get('./assets/api/all-requests.json');
  }

  getRequestByClientId(clientId: string) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.requests.getRequestsByClientId.replace('{clientId}', clientId));
    // return this.http.get('./assets/api/all-requests.json');
  }

  getRequestByRequestId(requestId: string) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.requests.getByRequestId.replace('{requestId}', requestId));
  }

  createRequest(requestBody) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.requests.createRequest, requestBody);
  }

  updateRequest(requestBody) {
    return this.http.put(this.baseUrl + APP_ENDPOINTS.requests.updateRequest.replace('{requestId}', requestBody.id), requestBody);
  }
}
