import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MediaService } from 'src/app/services/media.service';
import { RequestService } from 'src/app/services/request.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-update-client-dashboard',
  templateUrl: './update-client-dashboard.component.html',
  styleUrls: ['./update-client-dashboard.component.scss']
})
export class UpdateClientDashboardComponent implements OnInit {

  @Input() userDetails: any;
  isLoading: boolean;
  userId = '';
  uploadForm: FormGroup;
  requestList: any;
  uploadingFile: any;
  alertMessage = '';
  segregatedFolders: any;
  showYear = '';
  showReqList: any;
  mediaList: any;
  isError = false;
  showMedList: any;

  returnValidateList: any;
  returnSubmitList: any;

  constructor(
    private userService: UserService,
    private requestService: RequestService,
    private formBuilder: FormBuilder,
    private mediaService: MediaService
  ) { }



  ngOnInit() {
    this.showReqList = [];
    this.userId = this.userDetails.userId;
    this.getAllRequests();
    // this.viewDocuments();
    this.getReturnValidationFiles();
    this.getReturnSubmitFiles();
    // this.getUserDeets();
  }

  getUserDeets() {
    this.userService.getUserDetails(this.userId).subscribe((data) => {
      this.userDetails = data;
      this.getAllRequests();
      this.viewDocuments();
      this.getReturnValidationFiles();
    });
  }

  getReturnValidationFiles() {
    this.mediaService.getReturnValidationMediaByClientId(this.userId).subscribe((data) => {
      this.returnValidateList = data;
    }, (error) => {
    })
  }

  getReturnSubmitFiles() {
    this.mediaService.getReturnSubmitMediaByClientId(this.userId).subscribe((data) => {
      this.returnSubmitList = data;
    }, (error) => {
    })
  }

  getAllRequests() {
    this.isLoading = true;
    this.setUploadForm();
    this.requestService.getRequestByClientId(this.userId).subscribe((data: any) => {
        // this.mode = 'viewRequests';
        this.requestList = this.showReqList = data;
        this.segregatedFolders = [];
        this.getsegregatedFolders();
        this.viewDocuments();
        this.isLoading = false;
      }, (error) => {
        this.requestList = [];
        this.isLoading = false;
      });
  }

  getsegregatedFolders() {
    if (this.requestList && this.requestList.length) {
      this.segregatedFolders.push(this.requestList[0].year);
    }
    this.requestList.forEach((item) => {
      if (this.segregatedFolders.indexOf(item.year) === -1) {
        this.segregatedFolders.push(item.year);
      }
    })
  }

  setUploadForm() {
    this.uploadForm = this.formBuilder.group({
      title: '',
      file: '',
      clientId: '',
      requestId: ''
    })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.uploadingFile = event.target.files[0];
      this.uploadForm.get('file').setValue(event.target.files[0]);
    }
  }

  uploadFile(requestId, year) {
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value, this.uploadingFile.name);
    formData.append('title', this.uploadForm.get('title').value);
    formData.append('clientId', this.userId);
    formData.append('requestId', requestId);
    formData.append('year', year);
    this.mediaService.uploadDocument(formData).subscribe((data) => {
      this.resetUploadForm();
      this.alertMessage = 'Document has been uploaded to the request';
      this.callAlertMessage();
      this.getAllRequests();
      this.viewDocuments();
    }, (error) => {
      if (error && error.status === 200) {
        this.resetUploadForm();
        this.alertMessage = 'Document has been uploaded to the request';
        this.callAlertMessage();
        this.getAllRequests();
        // this.viewDocuments();
      }
    }) 
  }

  resetUploadForm() {
    this.uploadForm.get('title').setValue('');
    this.uploadForm.get('file').setValue('');
    this.uploadForm.get('clientId').setValue('');
    this.uploadForm.get('requestId').setValue('');
  }

  callAlertMessage() {
    setTimeout(() => {
      this.alertMessage = '';
      // this.mode = '';
    }, 3000);
  }

  updateValidateMediaStatus(validateMedia) {
    if (validateMedia.mediaStatus === 'unverified') {
      validateMedia.mediaStatus = 'verified';
    } else if (validateMedia.mediaStatus === 'verified'){
      validateMedia.mediaStatus = 'unverified';
    }
    this.mediaService.updateValidateMediaStatus(validateMedia, validateMedia.id).subscribe((data) => {
      this.alertMessage = 'Status has been updated';
      this.callAlertMessage();
      console.log('alertMessage', this.alertMessage);
      this.getReturnValidationFiles();
    }, (error) => {
      if (error && error.status === 200) {
        this.alertMessage = 'Status has been updated';
        this.callAlertMessage();
        console.log('alertMessage', this.alertMessage);
        this.getReturnValidationFiles();
      } else {
        this.alertMessage = 'Status cannot be updated. Try again later.';
        this.callAlertMessage();
      }
    })
  }

  selectShowYear(year) {
    this.showYear = year;
    this.showRequestByYear();
  }

  showRequestByYear() {
    this.showReqList = this.requestList.filter(item => item.year === this.showYear);
  }

  showDocumentsByYear() {
    this.showMedList = this.mediaList.filter(item => item.year === this.showYear);
  }

  viewDocuments() {
    this.isLoading = true;
    this.setUploadForm();
    
    this.mediaService.getMediaDetails(this.userId).subscribe((data: any) => {
      this.mediaList = data && data.mediaDetails; 
      this.isLoading = false;
      this.showMedList = this.mediaList
      // this.adjustModalConfig();
      // this.mapMediatoRequests();
    }, (error) => {
      this.isLoading = false;
    })
  }

  mapMediatoRequests() {
    this.requestList.forEach((req) => {
      const med = this.mediaList.filter((item) => item.requestId === req.requestId);
      if (med && med.length) {
        req.media = med[0].mediaId;
      } else {
        req.media = '';
      }
    })
  }

  downloadMedia(media, title) {
    this.mediaService.downloadDocumentById(media.mediaId).subscribe((data: any) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      const extension = media && media.extension || '.pdf';
      link.download = title + extension;
      link.click();
    }, (error) => {
    });
  }



}
