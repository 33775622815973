import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-carousel',
  templateUrl: './services-carousel.component.html',
  styleUrls: ['./services-carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ServicesCarouselComponent implements OnInit {

  constructor() { }

  @Input() set incomingSlide(value: any) {
    this.currentSlide = value;
  };
  slides = [];
  currentSlide = 0;


  ngOnInit() {
    this.slides = [
      { src: "/assets/images/dox-app-img1.jpg" },
      { src: "/assets/images/dox-app-img2.jpg" },
      { src: "/assets/images/dox-app-img3.jpg" }
    ];
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }

}
