import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-will-preparation',
  templateUrl: './service-will-preparation.component.html',
  styleUrls: ['./service-will-preparation.component.scss']
})
export class ServiceWillPreparationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openFooter(event) {
    console.log('event', event);
  }

}
