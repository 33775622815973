import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  userObj = {
    userId: '',
    password: ''
  }

  otpValue = '';

  loggedUser = '';

  recievedOtp: boolean;
  isLoading = false;
  isError = false;
  alertMessage = '';
  showPassword = false;

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    if (window.localStorage.getItem('userId')) {
      window.localStorage.removeItem('userId'); 
    }
  }

  validatePassword() {
    this.isLoading = true;
    this.userService.getUserOtp(this.userObj).subscribe((data) => {
      this.isLoading = false;
      this.isError = false;
      this.loggedUser = data;
      if (data && data.length) {
        this.callAlert('Password validation success.')
        this.recievedOtp = true;
      } else {
        this.callAlert('Password validation failed.')
      }
    }, (error) => {
      this.isLoading = false;
      if (error && error.status === 200) {
        this.recievedOtp = true;
        this.isError = false;
        this.callAlert('Password validation success.')
      } else {
        this.recievedOtp = false;
        this.isError = true;
        this.callAlert('Password validation failed.')
      }
    })
  }

  validateOtp() {
    this.isLoading = true;
    const req = {
      userId: this.loggedUser,
      otp: this.otpValue
    }

    this.userService.validateUserOtp(req).subscribe((data) => {
      this.userService.setLoggedUser(this.loggedUser);      
      window.localStorage.setItem('userId', this.loggedUser);
      this.isLoading = false;
      this.isError = false;
      this.callAlert('OTP validation success.')
      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 200)
      this.userService.isLoggedIn.next(true);
    }, (error) => {
      this.isLoading = false;
      if (error && error.status === 200) {
        this.router.navigate(['/dashboard']);
        this.isError = false;
        this.callAlert('OTP validation success.')
        this.userService.setLoggedUser(this.loggedUser);
      } else {
        this.isError = true;
        this.callAlert('OTP validation failed.')
        this.router.navigate(['/login']);
      }
    })
  }

  callAlert(message) {
    this.alertMessage = message;
    setTimeout(() => {
      this.alertMessage = '';
    }, 3000);
  }

}
