import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MediaService } from 'src/app/services/media.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {

  isLoading: boolean;
  @Input() userId: any;
  mode = '';
  requestList: any;
  segregatedFolders = [];
  mediaList: any;
  uploadingFile: any;
  uploadForm: FormGroup;
  alertMessage = '';
  showYear = '';
  showMedList = [];
  

  constructor(
    private requestService: RequestService,
    private mediaService: MediaService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  getAllRequests() {
    this.showMedList = [];
    this.isLoading = true;
    this.setUploadForm();
    this.requestService.getRequestByClientId(this.userId).subscribe((data: any) => {
        this.mode = 'viewRequests';
        this.requestList = data;
        // this.segregatedFolders = [];
        // this.getsegregatedFolders();
        this.isLoading = false;
      }, (error) => {
        this.requestList = [];
        this.isLoading = false;
      });
  }

  getsegregatedFolders() {
    if (this.mediaList && this.mediaList.length) {
      this.segregatedFolders.push(this.mediaList[0].year);
    }
    this.mediaList.forEach((item) => {
      if (this.segregatedFolders.indexOf(item.year) === -1) {
        this.segregatedFolders.push(item.year);
      }
    })
  }

  viewDocuments() {
    this.isLoading = true;
    this.mode = 'viewDocuments';
    this.setUploadForm();
    
    this.mediaService.getMediaDetails(this.userId).subscribe((data: any) => {
      this.mediaList = data && data.mediaDetails; 
      this.isLoading = false;
      this.segregatedFolders = [];
      this.getsegregatedFolders();
      // this.adjustModalConfig();
    }, (error) => {
      this.isLoading = false;
    })
  }

  closeView() {
    this.mode = '';
    this.requestList = [];
    this.mediaList = [];
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.uploadingFile = event.target.files[0];
      this.uploadForm.get('file').setValue(event.target.files[0]);
    }
  }

  uploadFile(requestId, year) {
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value, this.uploadingFile.name);
    formData.append('title', this.uploadForm.get('title').value);
    formData.append('clientId', this.userId);
    formData.append('requestId', requestId);
    formData.append('year', year);
    this.mediaService.uploadDocument(formData).subscribe((data) => {
      this.resetUploadForm();
      this.getAllRequests();
      this.alertMessage = 'Document has been uploaded to the request';
      this.callAlertMessage();
    }, (error) => {
      if (error && error.status === 200) {
        this.resetUploadForm();
        this.alertMessage = 'Document has been uploaded to the request';
        this.callAlertMessage();
      }
    }) 
  }

  downloadMedia(mediaId, title) {
    this.mediaService.downloadDocumentById(mediaId).subscribe((data: any) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = title + ".pdf";
      link.click();
    }, (error) => {
    });
  }

  setUploadForm() {
    this.uploadForm = this.formBuilder.group({
      title: '',
      file: '',
      clientId: '',
      requestId: ''
    })
  }

  resetUploadForm() {
    this.uploadForm.get('title').setValue('');
    this.uploadForm.get('file').setValue('');
    this.uploadForm.get('clientId').setValue('');
    this.uploadForm.get('requestId').setValue('');
  }

  callAlertMessage() {
    setTimeout(() => {
      this.alertMessage = '';
      this.mode = '';
    }, 3000);
  }

  selectShowYear(year) {
    this.showYear = year;
    this.showMedList = this.mediaList.filter(item => item.year === this.showYear);
  }

}
