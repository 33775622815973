import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { APP_ENDPOINTS } from '../pages/constants/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  adminId: string;
  loggedUser: string;
  isLoggedIn = new Subject();
  baseUrl = "https://cp-tralsawala-backend.herokuapp.com/"

  getUserDetails(userId: string) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.user.getUserById.replace('{id}', userId));
    // return this.http.get('./assets/api/user-detail-admin.json');
  }

  getAllUsers() {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.user.getAllUsers);
    // return this.http.get('./assets/api/all-users.json');
  }

  getClientDetails(userId: string) {
    return this.http.get('./assets/api/user-detail-client.json');
  }

  createUser(userObj) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.user.createUser, userObj);
  }

  updateUser(userObj, userId) {
    return this.http.put(this.baseUrl + APP_ENDPOINTS.user.updateUser.replace('{id}', userId), userObj);
  }

  getUserOtp(userObj) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.user.getLoginOtp,
        userObj,
        {headers: new HttpHeaders().set('Content-Type', 'application/json'), responseType: 'text'});
  }

  validateUserOtp(userObj) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.user.validateOtp, userObj);
  }

  setAdminId(adminId: string) {
    this.adminId = adminId;
  }

  setLoggedUser(userId: string) {
    this.loggedUser = userId;
  }

  getloggedIn() {
    return this.isLoggedIn;
  }

  resetUserPassword(userObj) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.user.resetPassword, userObj);
  }
}
