import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'docs-review';
  currentSection = 'dashboard';
  showLogin: boolean;

  navHeaders = [
    {
      text: 'Home',
      link: '/dashboard',
      active: false
    },
    {
      text: 'About',
      link: '/about-us',
      active: false
    }
  ];

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.subscribeToRoute();
    this.subscribeLogin();
  }

  subscribeToRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('dashboard')) {
          this.currentSection = this.navHeaders[0].text;
        } else if (event.url.includes('about')) {
          this.currentSection = this.navHeaders[1].text;
        } else if (event.url.includes('service')) {
          this.currentSection = this.navHeaders[2].text;
        } else if (event.url.includes('news')) {
          this.currentSection = this.navHeaders[3].text;
        } else if (event.url.includes('contact')) {
          this.currentSection = this.navHeaders[4].text;
        }
      }
    });
  }

  subscribeLogin() {
    this.userService.isLoggedIn.subscribe((data) => {
      console.log(data);
      if (data) {
        this.showLogin = true;
      } else {
        this.showLogin = false;
      }
    })
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   window.localStorage.removeItem('userId');
  // }

  @HostListener('window:onunload', ['$event'])
  onunloadHandler(event) {
    window.localStorage.removeItem('userId');
  }

  ngOnDestroy() {
    window.localStorage.removeItem('userId');
    console.log('destroy');
  }
}
