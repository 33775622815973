import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_ENDPOINTS } from '../pages/constants/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class ContactFormsService {

  baseUrl = "https://cp-tralsawala-backend.herokuapp.com/"

  constructor(private http: HttpClient) { }

  

  createForm(formObj) {
    return this.http.post(this.baseUrl + APP_ENDPOINTS.contactForms.createForm, formObj);
  }

  getAllForms() {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.contactForms.getAllForm);
  }

  getFormById(formId) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.contactForms.getFormById.replace('{id}', formId));
  }

  deleteFormById(formId) {
    return this.http.delete(this.baseUrl + APP_ENDPOINTS.contactForms.deleteFormById.replace('{id}', formId));
  }

  deleteAllForms() {
    return this.http.delete(this.baseUrl + APP_ENDPOINTS.contactForms.deleteAllForms);
  }
}
