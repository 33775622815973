import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MediaService } from 'src/app/services/media.service';
import { RequestService } from 'src/app/services/request.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  isLoading: boolean;
  userList = [];
  selectedClientId = '';
  showYear = '';
  mode = '';
  mainMediaData: any;
  mediaList = [];
  requestList: any;
  segregatedFolders = [];
  showReqList: any;
  showMedList: any;
  modalLoading: boolean;
  requestObj: any;
  showModal: boolean;
  requestTitle: any;
  requestDescription: any;
  selectedYear: any;
  alertMessage = '';
  uploadForm: FormGroup;
  returnValidationForm: FormGroup;
  returnSubmitForm: FormGroup;
  adminFormMode = '';
  uploadingFile: any;
  adminFormYear = '';
  isError = false;
  showAdminDocumentsFlag = false;

  returnValidationList: any;
  returnSubmitList: any;

  @ViewChild('modalScope') modalComp:ElementRef;
  @ViewChild('closeModal') closeModal:ElementRef;

  constructor(
    private userService: UserService,
    private requestService: RequestService,
    private mediaService: MediaService,
    private formBuilder: FormBuilder
  ) { }


  ngOnInit() {
    this.fetchAllUsers();
  }

  generateValidationForm() {
    this.returnValidationForm = this.formBuilder.group({
      title: '',
      year: '',
      clientId: this.selectedClientId,
      file: ''
    })
    setTimeout(() => {
      this.adminFormMode = 'validate';
    }, 10);
    
  }

  generateSubmitForm() {
    this.returnSubmitForm = this.formBuilder.group({
      title: '',
      year: '',
      clientId: this.selectedClientId,
      file: ''
    })
    setTimeout(() => { 
      this.adminFormMode = 'submit';
    }, 10);
  }

  cancelForms() {
    this.mode = '';
    this.adminFormMode = '';
    this.returnValidationForm = undefined;
    this.returnSubmitForm = undefined;
    this.showAdminDocumentsFlag = false;
  }

  uploadReturnValidation() {
    const formData = new FormData();
    this.isLoading = true;
    formData.append('file', this.returnValidationForm.get('file').value, this.uploadingFile.name);
    formData.append('title', this.returnValidationForm.get('title').value);
    formData.append('clientId', this.selectedClientId);
    formData.append('year', this.returnValidationForm.get('year').value);
    this.mediaService.uploadReturnValidationMedia(formData).subscribe((data) => {
      console.log('return validation upload success');
      this.isLoading = false;
      this.alertMessage = 'Information Document uploaded';
      this.isError = false;
      this.callAlertMessage();
      this.cancelForms();
    }, (error) => {
      this.isLoading = false;
      this.alertMessage = '';
      if (error && error.status == 200) {
        console.log('return validation upload success');
        this.alertMessage = 'Information Document uploaded';
        this.cancelForms();
        this.isError = false;
      } else {
        this.isError = true;
        this.alertMessage = 'Information Document upload failed. Try again later.';
      }
      this.callAlertMessage();
    });
  }

  uploadSubmitValidation() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', this.returnSubmitForm.get('file').value, this.uploadingFile.name);
    formData.append('title', this.returnSubmitForm.get('title').value);
    formData.append('clientId', this.selectedClientId);
    formData.append('year', this.returnSubmitForm.get('year').value);
    this.mediaService.uploadReturnSubmitMedia(formData).subscribe((data) => {
      console.log('return validation upload success');
      this.isLoading = false;
      this.isError = false;
      this.cancelForms();
      this.alertMessage = 'Pre-return Filing documents uploaded';
      this.callAlertMessage();
    }, (error) => {
      if (error && error.status == 200) {
        this.isLoading = false;
        this.isError = false;
        console.log('return validation upload success');
        this.alertMessage = 'Pre-return Filing documents uploaded';
        this.cancelForms();
      } else {
        this.alertMessage = 'Pre-return Filing documents upload failed. Try again later.';
        this.isError = true;
      }
      this.callAlertMessage();
    });

  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      if (this.adminFormMode === 'validate') {
        this.uploadingFile = event.target.files[0];
        this.returnValidationForm.get('file').setValue(event.target.files[0]);
      } else if (this.adminFormMode === 'submit') {
        this.uploadingFile = event.target.files[0];
        this.returnSubmitForm.get('file').setValue(event.target.files[0]);
      }
    }
  }

  fetchAllUsers() {
    this.isLoading = true;
    this.userService.getAllUsers().subscribe((data: any) => {
      this.userList = data && data.filter(item => item.userType === 'client')
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.userList = [];
    })
  }

  selectClientId(user) {
    if (this.selectedClientId === user.userId) {
      this.selectedClientId = '';
    } else {
      this.selectedClientId = user.userId;
      this.segregatedFolders = [];
      this.fetchAllRequests();
      this.viewClientDocuments();
      this.getReturnSubmitFiles(this.selectedClientId);
      this.getReturnValidationFiles(this.selectedClientId);
    }
    this.showYear = '';
    this.mode = '';
  }

  fetchAllRequests() {
    this.isLoading = true;
    this.requestService.getRequestByClientId(this.selectedClientId).subscribe((data) => {
      // this.mode = 'viewRequests';
      this.requestList = data;
      this.isLoading = false;
      this.segregatedFolders = [];
      this.getsegregatedFolders();
    }, (error) => {
      this.requestList = undefined;
      this.isLoading = false;
    });
  }

  getsegregatedFolders() {
    if (this.requestList && this.requestList.length) {
      this.segregatedFolders.push(this.requestList[0].year);
    }
    this.requestList.forEach((item) => {
      if (this.segregatedFolders.indexOf(item.year) === -1) {
        this.segregatedFolders.push(item.year);
      }
    })
  }

  viewClientDocuments() {
    this.isLoading = true;
    // this.mode = 'viewDocuments';
    this.mediaService.getMediaDetails(this.selectedClientId).subscribe((data: any) => {
      this.mainMediaData = data;
      this.mediaList = data && data.mediaDetails; 
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    })
  }

  selectShowYear(year) {
    this.mode = '';
    this.showYear = year;
  }

  showRequestByYear() {
    this.mode = 'viewRequests';
    this.showReqList = this.requestList.filter(item => item.year === this.showYear);
  }

  showDocumentsByYear() {
    this.mode = 'viewDocuments';
    this.showMedList = this.mediaList.filter(item => item.year === this.showYear);
  }

  createRequestMode() {
    this.mode = 'createRequest';
  }

  updateRequestStatus(id, request) {
    this.isLoading = true;
    this.modalLoading = true;
    if (request.status === 'open') {
      request.status = 'closed';
    } else {
      request.status = 'open';
    }

    this.requestService.updateRequest(request).subscribe((data) => {
      this.isLoading = false;
      this.modalLoading = false;
      this.closeModal.nativeElement.click();
      this.requestObj = undefined;
      this.alertMessage = 'Request has been updated';
      this.isError = false;
      this.callAlertMessage();
    }, (error) => {
      this.isLoading = false;
      if (error && error.status == 200) {
        this.requestObj = undefined;
        this.alertMessage = 'Request has been updated';
        this.isError = false;
        this.callAlertMessage();
      } else {
        this.alertMessage = 'Request cannot be updated now. Try again later';
        this.isError = true;
        this.callAlertMessage();
      }
      this.modalLoading = false;
      this.closeModal.nativeElement.click();
    })
  }

  updateMediaStatus(mediaData, index) {
    this.isLoading = true;
    if (mediaData.mediaStatus === 'verified') {
      mediaData.mediaStatus = 'unverified'
    } else {
      mediaData.mediaStatus = 'verified'
    }
    const udpateIndex = this.mediaList.findIndex(item => item.mediaId === mediaData.mediaId);
    this.mediaList[udpateIndex] = mediaData;
    const requestBody = this.mainMediaData;
    requestBody.mediaDetails = this.mediaList;
    this.showModal = true;
    this.mediaService.updateMediaDetails(requestBody).subscribe((data: any) => {
      this.isLoading = false;
      this.alertMessage = 'Media status has been updated';
      this.isError = false;
      this.callAlertMessage();
      this.getRequestDetailsById(mediaData.requestId);
    }, (error) => {
      if (error && error.status == 200) {
        this.alertMessage = 'Media status has been updated';
        this.getRequestDetailsById(mediaData.requestId);
        this.isError = false;
      } else {
        this.alertMessage = 'Media status cannot be updated. Try again later.';
        this.isError = true;
      }
      this.callAlertMessage();
      this.isLoading = false;
    });
  }

  getRequestDetailsById(requestId) {
    this.requestService.getRequestByRequestId(requestId).subscribe((data) => {
      this.requestObj = data;
      if (this.requestList[this.requestList.findIndex(item => item.id === requestId)].status === 'closed') {
        this.requestList[this.requestList.findIndex(item => item.id === requestId)].status = 'open'
      } else {
        this.requestList[this.requestList.findIndex(item => item.id === requestId)].status = 'closed'
      }
      this.modalComp.nativeElement.click();
    }, (error) => {
      this.requestObj = undefined;
    });
  }

  downloadMedia(media, title) {
    this.mediaService.downloadDocumentById(media.mediaId).subscribe((data: any) => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      const extension = media && media.extension || '.pdf';
      link.download = title + extension;
      link.click();
    }, (error) => {
    });
  }

  closeView() {
    this.mode = '';
    this.requestList = [];
    this.mediaList = [];
  }

  createNewRequest() {
    this.isLoading = true;
    const requestBody = {
      "requestForUser": this.selectedClientId,
      "requestByUser": this.userService.adminId,
      "title": this.requestTitle,
      "description": this.requestDescription,
      "status": "open",
      "year": this.selectedYear
    };
    
    this.requestService.createRequest(requestBody).subscribe((data) => {
      this.requestTitle = '';
      this.requestDescription = '';
      this.isLoading = false;
      this.isError = false;
      this.alertMessage = 'Request has been published';
      this.callAlertMessage();
      this.fetchAllRequests();
    }, (error) => {
      this.isLoading = false;
      this.requestTitle = '';
      this.requestDescription = '';
      if (error && error.status === 200) {
        this.alertMessage = 'Request has been published';
        this.fetchAllRequests();
        this.callAlertMessage();
        this.isError = true;
      } else {
        this.alertMessage = 'Request cannot be published. Try again later.';
        this.callAlertMessage();
        this.isError = true;
      }
    })
  }

  callAlertMessage() {
    setTimeout(() => {
      this.alertMessage = '';
      this.mode = '';
    }, 3000);
  }

  // uploadFile(requestId, year) {
  //   const formData = new FormData();
  //   formData.append('file', this.uploadForm.get('file').value, this.uploadingFile.name);
  //   formData.append('title', this.uploadForm.get('title').value);
  //   formData.append('clientId', this.selectedClientId);
  //   formData.append('year', year);
  //   this.mediaService.uploadDocument(formData).subscribe((data) => {
  //     this.resetUploadForm();
  //     this.alertMessage = 'Document has been uploaded to the request';
  //     this.callAlertMessage();
  //     this.viewDocuments();
  //   }, (error) => {
  //     if (error && error.status === 200) {
  //       this.resetUploadForm();
  //       this.alertMessage = 'Document has been uploaded to the request';
  //       this.callAlertMessage();
  //       this.viewDocuments();
  //     }
  //   }) 
  // }

  showAdminDocuments() {
    this.showAdminDocumentsFlag = true;
    this.mode = 'adminDocs';
  }

  getReturnValidationFiles(userId) {
    this.isLoading = true;
    this.mediaService.getReturnValidationMediaByClientId(userId).subscribe((data) => {
      this.returnValidationList = data;
      this.isLoading = false;
    }, (error) => {
    })
  }

  getReturnSubmitFiles(userId) {
    this.isLoading = true;
    this.mediaService.getReturnSubmitMediaByClientId(userId).subscribe((data) => {
      this.returnSubmitList = data;
      this.isLoading = false;
    }, (error) => {
    })
  }

  resetUploadForm() {
    this.uploadForm.get('title').setValue('');
    this.uploadForm.get('file').setValue('');
    this.uploadForm.get('clientId').setValue('');
    this.uploadForm.get('year').setValue('');
  }

}
