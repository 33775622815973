import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  @Output() openContactModal = new EventEmitter<string>();

  ngOnInit() {
  }

  openContactUs() {
    this.openContactModal.emit('true');
  }

  openAboutUs() {
    window.location.href = '/about-us';
  }

}
