import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactFormsService } from 'src/app/services/contact-forms.service';
import { MediaService } from 'src/app/services/media.service';
import { RequestService } from 'src/app/services/request.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router,
    private requestService: RequestService,
    private mediaService: MediaService,
    private formBuilder: FormBuilder,
    private contactFormService: ContactFormsService
  ) { }

  userType: string;
  // loggedIn: boolean = false;
  loggedIn: boolean = true;
  userDetails: any;
  userList = [];
  requestList: any;
  showReqList: any;
  showMedList: any;
  segregatedFolders = [];
  mediaList = [];

  //admin
  userId = ''

  //client
  // userId = '615d93e8e7bb95113dae5539'

  mode: string;
  uploadingFile: any;
  uploadForm: FormGroup;
  isLoading = false;
  alertMessage = '';
  serviceShortDesc = [];
  currentSection = 0;
  currentSlide;
  selectedClientId;
  mainMediaData: any;
  requestTitle: any;
  requestDescription: any;
  modalLoading = false;
  requestObj: any;
  showModal = false;
  selectedYear = '';
  showYear = '';

  // contact us data
  contactData = {
    fname: '',
    lname: '',
    emailId: '',
    phone: '',
    state: '',
    message: ''
  }

  stateDropdowns = ['Gujarat', 'Maharashtra', 'Madhya Pradesh', 'Rajasthan', 'West Bengal'];

  @ViewChild('modalScope') modalComp:ElementRef;
  @ViewChild('closeModal') closeModal:ElementRef;

  @ViewChild('contactModalScope') contactModalScope:ElementRef;
  @ViewChild('closeContactModal') closeContactModal:ElementRef;

  ngOnInit() {
    this.userId = window.localStorage.getItem('userId');
    if (this.userId) {
      this.loggedIn = true;
      this.userService.loggedUser = window.localStorage.getItem('userId');
      this.userService.isLoggedIn.next(true);
    } else {
      this.loggedIn = false;
      this.userService.isLoggedIn.next(false);
    }

    if (this.loggedIn === false) {
      this.getServiceShortDesc();
    } else {
      this.getUserDetails();
    }
  }

  getServiceShortDesc() {
    this.serviceShortDesc = [
      {
        para: 'We provide service for filing income tax returns for individuals, salaried and self employed and partnership firms.',
        header: 'Income Tax Return Filing'
      },
      {
        para: 'We provide long term support to our clients for ensuring that proper tax planning is done for our clients.',
        header: 'Taxation Planning'
      },
      {
        para: 'Help prepare will for client  and /or his relatives.',
        header: 'Will Preparation'
      }
    ];
  }

  onScroll(event) {
    this.checkScrollFeasibility();
  }

  checkScrollFeasibility() {
    for (let i = 0; i < this.serviceShortDesc.length; i++) {
      const element = document.getElementById('desc' + i);
      const position = element.getBoundingClientRect();  
      if(position.top >= 0 && position.bottom <= window.innerHeight) {
        this.currentSection = i;
        return;
      }
    }
  }

  getUserDetails() {
    this.isLoading = true;
    this.userService.getUserDetails(this.userId).subscribe((data: any) => {
      this.userDetails = data;
      this.userType = data && data.userType;
      if (this.userType === 'admin') {
        this.userService.setAdminId(this.userId);
      }
      this.isLoading = false;
    }, (error) => {
      this.userDetails = undefined;
      this.isLoading = false;
    })
  }

  adjustModalConfig() {
    var myModal = document.getElementById('myModal')
    var myInput = document.getElementById('myInput')

    myModal.addEventListener('shown.bs.modal', function () {
      myInput.focus()
    })
  }

  getRequestDetailsById(requestId) {
    this.requestService.getRequestByRequestId(requestId).subscribe((data) => {
      this.requestObj = data;
      this.modalComp.nativeElement.click();
    }, (error) => {
      this.requestObj = undefined;
    });
  }

  openFooter(event) {
    this.contactModalScope.nativeElement.click();
  }

  createContactForm() {
    this.isLoading = true;
    this.contactFormService.createForm(this.contactData).subscribe((data) => {
      this.callAlertMessage('Message successfully sent.')
      this.closeContactModal.nativeElement.click();
    },(error) => {
      this.isLoading = false;
      if(error && error.status == 200) {
        this.callAlertMessage('Message successfully sent.')
      } else {
        this.callAlertMessage('Message couldn`t be sent. Try again later.')
      }
    });
  }

  callAlertMessage(message) {
    this.alertMessage = message;
    setTimeout(() => {
      this.alertMessage = '';
    }, 3000);
  }
}
