import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-detail-home',
  templateUrl: './user-detail-home.component.html',
  styleUrls: ['./user-detail-home.component.scss']
})
export class UserDetailHomeComponent implements OnInit {

  constructor(private userService: UserService) { }

  phoneNumber = '';
  email = '';
  address = '';
  profession = '';
  editMode = false;
  isLoading = false;
  alertMessage = '';
  isError = false;

  @Input() userDetail: any;

  ngOnInit() {
    
  }

  updateUserDetails() {
    this.isLoading = true;
    const req = {
      address: this.address,
      email: this.email,
      number: this.phoneNumber,
      proffession: this.profession,
      name: this.userDetail.name,
      userType: this.userDetail.userType
    }
    this.userService.updateUser(req, this.userDetail.userId).subscribe((data) => {
      if (data) {
        this.isLoading = false;
        this.isError = false;
        this.setAlertMessage('User details updated');
        this.userDetail = {
          address: this.address,
          email: this.email,
          number: this.phoneNumber,
          proffession: this.profession,
          name: this.userDetail.name,
          userType: this.userDetail.userType
        }
      }
    }, (error) => {
      this.isLoading = false;
      if (error && error.status === 200) {
        this.setAlertMessage('User details updated');
        this.isError = false;
        this.userDetail = {
          address: this.address,
          email: this.email,
          number: this.phoneNumber,
          proffession: this.profession,
          name: this.userDetail.name,
          userType: this.userDetail.userType
        }
      } else {
        this.isError = true;
        this.setAlertMessage('User details cannot be updated. Try again later');
      }
    })
    this.editMode = false;
  }

  editUserDetails() {
    this.address = this.userDetail.address;
    this.email = this.userDetail.email;
    this.phoneNumber = this.userDetail.number;
    this.profession = this.userDetail.proffession;
    this.editMode = true;
  }

  cancelEditMode() {
    this.address = this.userDetail.address;
    this.email = this.userDetail.email;
    this.phoneNumber = this.userDetail.number;
    this.profession = this.userDetail.profession;
    this.editMode = false;
  }

  setAlertMessage(message) {
    this.alertMessage = message;
    setTimeout(() => {
      this.alertMessage = '';
    }, 3000); 
  }

}
