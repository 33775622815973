export const APP_ENDPOINTS = {

    requests: {
        getRequestsByClientId: 'api/review/request/requesting-user/{clientId}',
        createRequest: 'api/review/request/create',
        updateRequest: 'api/review/request/update/{requestId}',
        getByRequestId: 'api/review/request/{requestId}'
    },

    media: {
        getDocumentsByClientId: 'api/review/pdfs/clientId/{clientId}',
        getDocumentById: '',
        downloadDocumentById: 'api/review/documents/download/{mediaId}',
        uploadDocument: 'api/review/documents/upload',
        getMediaInfo: 'api/review/user-media/requesting-user/{clientId}',
        updateMediaList: 'api/review/user-media/update/{mediaId}',
        getValidationMedia: 'api/review/validation-returns/client-id/{clientId}',
        getSubmitMedia: 'api/review/return-submits/client-id/{clientId}',
        uploadValidationMedia: 'api/review/documents/return-validation/upload',
        uploadSubmitMedia: 'api/review/documents/return-submit/upload',
        updateValidateMedia: 'api/review/validation-returns/update/{collectionId}'
    },

    user: {
        getUserById: 'api/review/user/{id}',
        getAllUsers: 'api/review/user/getall',
        createUser: 'api/review/user/create',
        getLoginOtp: 'api/review/user-login/create',
        validateOtp: 'api/review/user-login/validate',
        updateUser: 'api/review/user/update/{id}',
        resetPassword: 'api/review/user-login/reset-password',
    },

    contactForms: {
        createForm: "api/review/contact-us/create",
        getAllForm: "api/review/contact-us/getall",
        getFormById: "api/review/contact-us/{id}",
        deleteFormById: "api/review/contact-us/delete/{id}",
        deleteAllForms: "api/review/contact-us/delete/all",
    }


}