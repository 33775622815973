import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-app',
  templateUrl: './about-app.component.html',
  styleUrls: ['./about-app.component.scss']
})
export class AboutAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openFooter(event) {

  }

}
