import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaService } from 'src/app/services/media.service';
import { RequestService } from 'src/app/services/request.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private requestService: RequestService,
    private mediaService: MediaService
  ) { }

  clientDetails: any;
  mode: string;
  requestList: any;
  requestTitle: string;
  requestDescription: string;
  mediaList = [];
  mainMediaData: any;
  isLoading = false;
  alertMessage = '';
  showModal = false;
  modalLoading = false;
  requestObj: any;

  @ViewChild('modalScope') modalComp:ElementRef;
  @ViewChild('closeModal') closeModal:ElementRef;

  @Input() adminId: string;
  clientId: string;
  

  ngOnInit() {
    this.subscribeToUserId();
  }

  adjustModalConfig() {
    var myModal = document.getElementById('myModal')
    var myInput = document.getElementById('myInput')

    myModal.addEventListener('shown.bs.modal', function () {
      myInput.focus()
    })
  }

  subscribeToUserId() {
    this.activatedRoute.params.subscribe(data => {
      console.log('route data', data);
      if (data && data.id) {
        this.clientId = data.id;
        this.getUserDetails(data.id)
      } else {
        // show error message
      }
    })
  }

  getUserDetails(id: string) {
    this.isLoading = true;
    this.userService.getUserDetails(id).subscribe((data: any) => {
      this.clientDetails = data;
      this.isLoading = false;
    }, (error) => {
      this.clientDetails = undefined;
      this.isLoading = false;
      console.log('Error fetching client details');
    });
  }

  fetchAllRequests() {
    this.isLoading = true;
    this.requestService.getRequestByClientId(this.clientId).subscribe((data) => {
      this.mode = 'viewRequests';
      this.requestList = data;
      this.isLoading = false;
    }, (error) => {
      this.requestList = undefined;
      this.isLoading = false;
    });
  }

  getDocuments() {

  }

  createRequestMode() {
    this.mode = 'createRequest';
  }

  createNewRequest() {
    this.isLoading = true;
    const requestBody = {
      "requestForUser": this.clientId,
      "requestByUser": this.userService.adminId,
      "title": this.requestTitle,
      "description": this.requestDescription,
      "status": "open"
    };
    console.log('requestBody', requestBody);
    console.log('requestTitle', this.requestTitle);
    
    this.requestService.createRequest(requestBody).subscribe((data) => {
      console.log('request created', data);
      this.requestTitle = '';
      this.requestDescription = '';
      this.isLoading = false;
      this.alertMessage = 'Request has been published';
      this.callAlertMessage();
    }, (error) => {
      this.isLoading = false;
      this.requestTitle = '';
      this.requestDescription = '';
      if (error && error.status === 200) {
        this.alertMessage = 'Request has been published';
        this.callAlertMessage();
      }
      console.log('request create failed', error);
    })
  }

  callAlertMessage() {
    setTimeout(() => {
      this.alertMessage = '';
    }, 3000);
  }

  updateRequestStatus(id, request) {
    this.isLoading = true;
    this.modalLoading = true;
    if (request.status === 'open') {
      request.status = 'closed';
    } else {
      request.status = 'open';
    }

    this.requestService.updateRequest(request).subscribe((data) => {
      console.log('request updated', data);
      this.isLoading = false;
      this.modalLoading = false;
      this.closeModal.nativeElement.click();
      this.requestObj = undefined;
    }, (error) => {
      this.isLoading = false;
      this.modalLoading = false;
      // this.closeModal.nativeElement.click();
      console.log('request update failed', error);
    })
  }

  viewDocuments() {
    this.isLoading = true;
    this.mode = 'viewDocuments';
    this.mediaService.getMediaDetails(this.clientId).subscribe((data: any) => {
      this.mainMediaData = data;
      this.mediaList = data && data.mediaDetails; 
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      console.log('Error fetching media details', error);
    })
  }

  downloadMedia(mediaId, mediaTitle) {
    this.mediaService.downloadDocumentById(mediaId).subscribe((data: any) => {
      console.log('download success', data);
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = mediaTitle + '.pdf';
      link.click();
    }, (error) => {
      console.log('download failure', error);
    });
  }

  updateMediaStatus(mediaData, index) {
    this.isLoading = true;
    if (mediaData.mediaStatus === 'verified') {
      mediaData.mediaStatus = 'unverified'
    } else {
      mediaData.mediaStatus = 'verified'
    }

    const requestBody = this.mainMediaData;
    requestBody.mediaDetails = this.mediaList;
     this.showModal = true;
    this.mediaService.updateMediaDetails(requestBody).subscribe((data: any) => {
      console.log('Media details updated');
      this.isLoading = false;
      this.getRequestDetailsById(mediaData.requestId);
    }, (error) => {
      if (error && error.status == 200) {
        this.getRequestDetailsById(mediaData.requestId);
      }
      this.isLoading = false;
      console.log('Media details update failed');
    });
  }

  getRequestDetailsById(requestId) {
    this.requestService.getRequestByRequestId(requestId).subscribe((data) => {
      this.requestObj = data;
      this.modalComp.nativeElement.click();
    }, (error) => {
      this.requestObj = undefined;
    });
  }

}
