import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {

  accountCreated: boolean;

  userObj = {
    name: '',
    number: '', 
    email: '',
    address: '',
    proffession: '',
    password: '',
    userType: 'client'
  }

  isLoading = false;
  isError = false;
  alertMessage = '';

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  submitCreateAccounDetails() {
    this.isLoading = true;
    this.userService.createUser(this.userObj).subscribe((data) => {
      this.accountCreated = true;
      this.isLoading = false;
      this.isError = false;
      this.callAlert('Registration completed successfully');
    }, (error) => {
      this.isLoading = false;
      if (error && error.status === 200) {
        this.accountCreated = true;
        this.isError = false;
        this.callAlert('Registration completed successfully');
      } else {
        this.accountCreated = false;
        this.isError = true;
        this.callAlert('Registration failed. Try again later.');
      }
    });
  }

  callAlert(message) {
    this.alertMessage = message;
    setTimeout(() => {
      this.alertMessage = '';
    }, 3000);
  }

}
