import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  phoneNumber = '';
  otpValue = '';
  alertMessage = '';
  updatedPassword = '';
  isError = false;
  isLoading = false;
  passwordChanged = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  updatePassword() {
    this.isLoading = true;
    const payload = {
      userId: this.phoneNumber,
      password: this.updatedPassword
    }
    this.userService.resetUserPassword(payload).subscribe(() => {
      this.alertMessage = 'Password updated successfully';
      this.isError = false;
      this.isLoading = false;
      this.passwordChanged = true;
    }, () => {
      this.isError = true;
      this.alertMessage = 'Password updated failed, please try again after some time';
      this.isLoading = false;
      this.passwordChanged = false;
    });
  }

}
