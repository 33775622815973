import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_ENDPOINTS } from '../pages/constants/app.endpoints';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  baseUrl = "https://cp-tralsawala-backend.herokuapp.com/"

  constructor(private http: HttpClient) { }

  getDocumentsById(clientId: string) {
    // return this.http.get(APP_ENDPOINTS.media.getDocumentsByClientId.replace('{clientId}', clientId));
    return this.http.get('./assets/api/all-documents.json');
  }

  downloadDocumentById(mediaId) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.media.downloadDocumentById.replace('{mediaId}', mediaId), {responseType: 'blob'});
  }

  uploadDocument(formData) {
    let headers = new HttpHeaders();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.baseUrl + APP_ENDPOINTS.media.uploadDocument, formData, {headers: headers} );
  }

  getMediaDetails(clientId) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.media.getMediaInfo.replace('{clientId}', clientId));
    // return this.http.get('./assets/api/all-documents.json');
  }

  updateMediaDetails(requestBody) {
    return this.http.put(this.baseUrl + APP_ENDPOINTS.media.updateMediaList.replace('{mediaId}', requestBody.id), requestBody);
  }

  getReturnValidationMediaByClientId(clientId) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.media.getValidationMedia.replace('{clientId}', clientId));
  }

  getReturnSubmitMediaByClientId(clientId) {
    return this.http.get(this.baseUrl + APP_ENDPOINTS.media.getSubmitMedia.replace('{clientId}', clientId));
  }

  uploadReturnValidationMedia(formData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.baseUrl + APP_ENDPOINTS.media.uploadValidationMedia, formData, {headers: headers} );
  }

  uploadReturnSubmitMedia(formData) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.baseUrl + APP_ENDPOINTS.media.uploadSubmitMedia, formData, {headers: headers} );
  }

  updateValidateMediaStatus(submitMedia, collectionId) {
    return this.http.put(this.baseUrl + APP_ENDPOINTS.media.updateValidateMedia.replace('{collectionId}', collectionId), submitMedia);
  }
}
