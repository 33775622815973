import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserDetailHomeComponent } from './pages/user-detail-home/user-detail-home.component';
import { UserInformationComponent } from './pages/user-information/user-information.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginSignupComponent } from './pages/login-signup/login-signup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutAppComponent } from './pages/about-app/about-app.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './pages/login/login.component';
import { ServicesCarouselComponent } from './widgets/services-carousel/services-carousel.component';
import { FooterComponent } from './widgets/footer/footer.component';
import { ServiceIncomeTaxFilingComponent } from './pages/service-income-tax-filing/service-income-tax-filing.component';
import { ServiceTaxPlanningComponent } from './pages/service-tax-planning/service-tax-planning.component';
import { ServiceWillPreparationComponent } from './pages/service-will-preparation/service-will-preparation.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { ClientDashboardComponent } from './pages/client-dashboard/client-dashboard.component';
import { UpdateClientDashboardComponent } from './pages/update-client-dashboard/update-client-dashboard.component';
import { CommonInterceptor } from './services/http-interceptor.service';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    AppComponent,
    UserDetailHomeComponent,
    UserInformationComponent,
    DashboardComponent,
    LoginSignupComponent,
    AboutAppComponent,
    ContactComponent,
    LoginComponent,
    ServicesCarouselComponent,
    FooterComponent,
    ServiceIncomeTaxFilingComponent,
    ServiceTaxPlanningComponent,
    ServiceWillPreparationComponent,
    AdminDashboardComponent,
    ClientDashboardComponent,
    UpdateClientDashboardComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
